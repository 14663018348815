import { Provider, QueryOption } from "./shared";

export enum ClientStatus {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
  ANALYZING = "ANALYZING",
}

export interface ClientResponse {
  id: number;
  name: string;
  website: string | null;
  status: ClientStatus;
  logo: string | null;
  createdBy: string;
  createdAt: string;
  colorCodingLayout: ClientLayout;
  targetMetrics: ClientTargetMetric[];
  defaultProvider: Provider | null;
  launchCockpitReport: number;
  workspaceId: number;
}

export interface Client {
  id: number;
  displayName: string;
  website: string | null;
  logo: string | null;
  status: ClientStatus;
  createdAt: string;
  createdBy: string;
  displayWebsite: string;
  displayImage: string;
  colorCodingLayout: ClientLayout;
  targetMetrics: ClientTargetMetric[];
  defaultProvider: Provider | null;
  launchCockpitReport: number;
  workspaceId: number;
}

export enum ClientLayout {
  RELATIVE = "RELATIVE",
  ABSOLUTE = "ABSOLUTE",
}

export type EmptyClientTargetMetric = {
  color: "RED" | "GREEN";
  property: string | null;
  queryOption: QueryOption | null;
  value: string;
};

export type FilledClientTargetMetric = {
  color: "RED" | "GREEN";
  property: string;
  queryOption: QueryOption;
  value: string;
};

export type ClientTargetMetric =
  | EmptyClientTargetMetric
  | FilledClientTargetMetric;
